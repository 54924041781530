/*------------------------------------------------------------------------
 # author    Eric Worman, News Media Corporation
 # copyright Copyright  2015. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   Newport Chamber
 # Path: /resources/assets/js/main.js
 -------------------------------------------------------------------------*/
//jQuery document initialization
//$(document).ready(function() {  });


//jQuery window resize listener
//$(window).resize(function() { });


//jQuery Scroll Listener
//$(window).scroll(function() { });


//Add all site initialize here
function initPage()
{

}

function deleteRecord(url, confirmDelete)
{
    if(confirmDelete == true)
    {
        $('#confirmDeletePanel').modal();
        return;
    }

    window.location.href=url;
}

function newSiteForm(action)
{
    if(action == 'show')
    {
        $('#newSiteForm').slideDown(250);
        var $target = $('html,body');
        $target.animate({scrollTop: $target.height()}, 1000);
    }
    else
    {
        $('#newSiteForm').slideUp(250);
    }


}

function selectSite(siteID)
{
    //Redirect to the manage content dashboard page
    window.location.href = 'admin/sites/manage/' + siteID;
}

/*
function removeImage(id, confirm)
{
    if(confirm == false)
    {
        //Remove any event handelers on the removeImage button
        $("#deleteImageButton").unbind();

        $('#deleteImageButton').bind('click', function(){ removeImage(true); });

        //Display the confirm deletion panel
        $('#confirmDeleteImagePanel').modal();
    }
    else if (confirm == true)
    {
        var url = "/admin/site/remove-logo/" + id;
        $.get(url, function(response){

            //Based on the image type, add the error message to the proper controls container
            $('#logoControls').html('Logo successfully removed.');
        });
    }
}
*/

function addStyle()
{
    //Retrieve class name of style from input field
    var className  = $("#newStyle").val();
    var token = $('input[name="_token"]').val();

    //If there is an issue with the class name, display an alert
    if(!className || className == '') { alert('Invalid class name.'); return false; }

    $.ajax({
        type: "POST",
        url: "admin/sites/add-style",
        data: { class_name: className, _token:token },
        dataType: "html",
        success: function(styleID) {
            //Hide the "No records found" message
            $('.noRecordsFound').css('display', 'none');

            //Build the HTML to create a new style entry in the current styles panel
            var html = '<li id="style-' + styleID + '" class="curRecord">';
            html += '<div class="input-group">';
                html += '<input id="editStyle-' + styleID + '" class="form-control editStyleInput" value="' + className + '">';
                html += '<span class="input-group-addon">';
                    html += '<span class="glyphicon glyphicon-pencil" onClick="updateStyle(\'edit\', \'' + styleID + '\');"></span>';
                html += '</span>';
                html += '<span class="input-group-addon">';
                    html += '<span class="glyphicon glyphicon-ban-circle" onClick="updateStyle(\'delete\', \'' + styleID + '\');"></span>';
                html += '</span>';
            html += '</div>';
            html += '</li>';

            $('#curRecords').find(' > li:last-child').before(html);
            $("#newStyle").val('');

        },
        error: function(html){
            alert(response);
        }
    });
}

function updateStyle(action, styleID)
{
    //Init vars
    var url;

    //Build URL based on action
    switch(action)
    {
        case "edit":
            url = 'admin/sites/edit-style';
            break;
        case "delete":
            url = 'admin/sites/delete-style';
            break;
        default:
            return false;
    }

    //Grab new style value
    var className = $('#editStyle-' + styleID).val();
    var token = $('input[name="_token"]').val();

    $.ajax({
        type: "POST",
        url: url,
        data: { class_name: className, _token:token, id:styleID },
        dataType: "html",
        success: function() {

            //If the action is delete, remove the field
            if(action == 'delete')
            {
                $('#style-' + styleID).fadeOut(250);
                $("#styleAlertMessage").html('Style successfully removed.');
            }
            else
            {
                $("#styleAlertMessage").html('Style successfully updated.');
            }

            $('#styleAlert').css('display', 'block');
            setTimeout(function(){ $('#styleAlert').fadeOut(250); }, 5000);

        },
        error: function(html){
            alert(response);
        }
    });
}


function viewImages(contentID)
{
    //Retrieve HTML snippet to display photo management for this article
    var url = "/admin/content/images/" + contentID;

    $.get(url, function(html){

        $.colorbox({html: html});

        $(document).bind('cbox_closed', function(){
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        });
    });
}

function removeImage(photoID, confirm)
{
    if(confirm == false)
    {
        $('#removeImageBtn').unbind(); //Unbind any previously attached click handlers
        $('#removeImageBtn').click(function(){ removeImage(photoID, true); });

        //Display the confirm deletion panel
        $('#confirmDeleteImgPanel').modal();

        return;
    }
    else if (confirm == true)
    {
        var url = "/admin/content/remove-image/" + photoID;

        $.get(url, function(html){

            //Update the colorbox with the new HTML
            $('#cboxLoadedContent').html(html);
        });
    }
}

function removeFile(url, target, confirm)
{
    if(confirm == false)
    {
        $('#deleteFile').unbind(); //Unbind any previously attached click handlers
        $('#deleteFile').click(function(){ removeFile(url, target, true); });

        //Display the confirm deletion panel
        $('#confirmDeleteFilePanel').modal();

        return;
    }
    else if (confirm == true)
    {
        $.get(url, function(html){

            //Update the logo controls panel
            $(target).html("File successfully removed.");
        });
    }
}

function populatePageSections(curSection)
{
    //Init func vars
    var selected = '';

    //Retrieve current page ID
    var pageID = $('#page_id').val();

    var url = '/admin/wireframes/sections/' + pageID;

    $.get(url, function(response){

        //If there are sections defined
        if(response.length > 0)
        {
            //Clear out current sections
            $("#page_section").html('');

            for(a=0; a<response.length; a++)
            {
                if(curSection == response[a].id) { selected = 'selected'; }  else { selected = ''; }

                $("#page_section").append('<option value="' + response[a].id + '" ' + selected + '>' + response[a].label + '</option>');
            }

            //Refresh the select picker
            $('#pageSectionGroup select').selectpicker('refresh');
        }
        else
        {
            $("#page_section").html('<option value="0">No Sections Found </option>');
        }

    });
}


function insertSection(curElm)
{
    //Grab section list and embed into tooltip
    var tooltipHTML = '<div class="clearfix inlineSectionSelector"><ul class="sectionDivisions">' + $(".sectionDivisions").html() + '</ul></div>';

    $(curElm).tooltip({placement: 'right', html: true, title: tooltipHTML, trigger: 'manual'});
    $(curElm).tooltip('toggle'); //Toggle the visibility of the current tooltip

    //Unbind all listeners on the create section icons
    $('.inlineSectionSelector .createSectionButton').unbind();


    //Grab the global position var set on the view, and increment by one to insert this section sequentially
    var curPosition = $(curElm).attr('data-position');
    var pos = parseInt(curPosition) + 1;

    //Rebind the event handler
    $('.inlineSectionSelector .createSectionButton').bind('click', function(){
        var type = $(this).attr("id");
        createSection(type, pos, true);
    });
}

function appendSection(type)
{
    //Count the number of sections and update the position var
    var numSections = $('.sectionPreviewContainer section').length;

    createSection(type, numSections, false);
}

function createSection(type, pos, increment)
{
    var url = "admin/wireframes/create-section";

    $.ajax({
        type: "POST",
        url: url,
        data: {page_id: $('#id').val(), _token: $('input[name="_token"]').val(), type:type, position: pos, increment:increment },
        dataType: "json",
        success: function (response) {

            //Append Section to page preview
            $('#page').html(response.html);

        },
        error: function (response) {
            alert(response.error);
        }

    });
}

function deleteSection(sectionID)
{
    var url = "admin/wireframes/delete-section";

    $.ajax({
        type: "POST",
        url: url,
        data: { page_id: $('#id').val(), _token: $('input[name="_token"]').val(), section_id:sectionID },
        dataType: "json",
        success: function (response) {

            //Update page preview
            $('#page').html(response.html);

        },
        error: function (response) {
            alert(response.error);
        }

    });
}

function saveLabel(label, sectionID, e)
{
    //Only hit if e is null or a return
    if (e == null || e.keyCode == 13)
    {
        $.ajax({
            type: "POST",
            url: "admin/wireframes/label-section",
            data: { _token: $('input[name="_token"]').val() , label: label, section_id: sectionID },
            success: function(response) {

                //console.log(response);
            },
            error: function(response){

                //console.log(response);
            }
        });
    }
}

function loadSections(curPage)
{
    var url = "/admin/wireframes/sections/" + curPage;
    var html;

    $('#currentPages li').removeClass('active');
    $('#page-' + curPage).addClass('active');

    //Grab the currently defined sections for this page
    $.get(url, function(sections){

        //Wipe out the existing HTML for the current sections container
        $("#currentSections").html('');

        //Loop through the sections and add them back in
        for(a=0; a<sections.length; a++)
        {
            html = '<li class="curPage" id="section-' + sections[a].id + '" onClick="loadExistingContent(\'' + sections[a].id + '\');">';
            html += '<span class="sectionName">' + sections[a].label + '</span>';
            html += '</li>';

            $('#currentSections').append(html);
        }

    });
}


function loadExistingContent(curSection)
{
    var url = "/admin/content/section-content/" + curSection;
    var html;

    $('#currentSections li').removeClass('active');
    $('#section-' + curSection).addClass('active');

    //Grab the currently defined sections for this page
    $.get(url, function(content){

        //If there's no content, return
        if(content.length == 1)
        {
            //Automatically redirect to the edit content page
            window.location.href = '/admin/content/edit/' + content[0].id;
            return;
        }

    });
}



function setVisibility(status)
{
    //Grab csrf token
    var token = $('input[name="_token"]').val();

    //Build URL to update page visibility
    var url = '/admin/wireframes/visibility';

    $.ajax({
        type: "POST",
        url: url,
        data: { page_id: $('#id').val(), _token:token, visibility:status },
        dataType: "html",
        success: function() {

           //Hide the publish/unpublish button based on status
            if(status == 'hidden')
            {
                $('#publishButton').css('display', 'block');
                $('#unpublishButton').css('display', 'none');
            }
            else
            {
                $('#publishButton').css('display', 'none');
                $('#unpublishButton').css('display', 'block');
            }
        },
        error: function(html){
            alert(response);
        }
    });
}

/*
function selectHeadlineStyle(className)
{
    //If the className is 0, return since that's an invalid class name
    if(className == 0) { return; }

    //Grab the formatted style name
    var name = $('#headlineStyle-' + className).attr('data-stylename');

    //Set the button text
    $('#headlineButton').html(name + ' <span class="caret"></span>');

    //Set the value in the input field
    $('#headlineStyle').val(className);
}

function selectSubheadStyle(className)
{
    //If the className is 0, return since that's an invalid class name
    if(className == 0) { return; }

    //Grab the formatted style name
    var name = $('#subheadStyle-' + className).attr('data-stylename');

    //Set the button text
    $('#subheadButton').html(name + ' <span class="caret"></span>');

    //Set the value in the input field
    $('#subheadStyle').val(className);
}
*/

function updateExistingContent(curPage)
{
    //Init func vars
    var html, homeless;

    //If curpage is undefined or 0 return
    if(curPage == undefined || curPage == '0') { return; }

    //Build the url to get the current content assigned to this page
    var url = '/admin/content/on-page/' + encodeURIComponent(curPage);

    $.get(url, function(response){

        //Clear the current records panel
        $('#curRecords').html('');

        //If there's no response, update the panel to show no content available
        if(response['content'].length == 0)
        {
            $('#curRecords').html('<li class="noRecordsFound">No content found.</li>');
        }
        else
        {
            //Loop through the response rebuilding the panel
            for(a=0; a<response['content'].length; a++)
            {
                //Set the homeless trigger
                homeless = '';

                //Loop through the homeless content and if this article is homeless add the appropriate class
                for(b=0; b<response['homeless'].length; b++)
                {
                    if(response['homeless'][b].id == response['content'][a].id) { homeless = 'homeless '; }
                }


                html = '<li class="curRecord ' + homeless + '">';
                html += '<a href="/admin/content/edit/' + response['content'][a].id + '">' + response['content'][a].headline + '</a>';
                html += '</li>';

                $("#curRecords").append(html);
            }
        }

    });

}
/*------------------------------------------------------------------------
 # author    Eric Worman, News Media Corporation
 # copyright Copyright  2015. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   DynaCMS
 # Path: /resources/assets/js/main.js
 -------------------------------------------------------------------------*/
//Init global vars
scrollThrottle = false;

/// /jQuery document initialization
//$(document).ready(function() {  });


//jQuery window resize listener
//$(window).resize(function() { });


//jQuery Scroll Listener
$(window).scroll(function() {

    //If scrollThrottle is true, return
    if(scrollThrottle == true) { return; }

    console.log('Scrolling!');

    //Update the scrolling throttle var to true
    scrollThrottle = true;

    //Grab the window height
    var h = $(window).height();
    var scrollH = $(document).scrollTop();

    //If the scroll height is greater than the height of the window, display the scroll to top widget
    if(scrollH > 100) { $('#scrollUp').fadeIn(500); } else { $('#scrollUp').fadeOut(500); }

    //Wait 1 second and set scroll throttle to false
    setTimeout(function(){ scrollThrottle = false; }, 100);


});


//Add all site initialize here
function initPage()
{

}
/*------------------------------------------------------------------------
 # author    Eric Worman, News Media Corporation
 # copyright Copyright  2015. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   Newport Chamber
 # Path: /resources/assets/js/directory.js
 -------------------------------------------------------------------------*/

function searchListings()
{
    //Grab search term
    var term = $('#searchTerm').val();
    var url = '/directory/search/' + encodeURIComponent(term);

    loadDirectoryURL(url, '#directoryContent');
}


function loadDirectoryURL(url, targetElm)
{
    //Load the given URL and inject the response into the appropriate target
    $.get(url, function(response){
        $(targetElm).html(response);
    });
}